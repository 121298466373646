import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { GET_INITIAL_LOCALE, SET_LOCALE } from 'src/app/util/space.util'
import { GET_INITIAL_TZ, SET_TZ } from 'src/app/util/time.util'
import { AppModule } from './app/app.module'

(async (): Promise<void> => {
  SET_LOCALE(await GET_INITIAL_LOCALE())

  SET_TZ(await GET_INITIAL_TZ())

  try { await platformBrowserDynamic().bootstrapModule(AppModule) } catch (err) { console.error(err) }
})().catch(e => { console.error(e) })
