import { Injectable, inject } from '@angular/core'
import { OKTA_CONFIG, type OktaConfig } from './okta.config'

@Injectable({ providedIn: 'root' })
export class OktaAuthConfigService {
  private OKTA_CONFIG = inject(OKTA_CONFIG, { optional: true })

  public getConfig (): OktaConfig | null {
    return this.OKTA_CONFIG
  }

  public setConfig (config: OktaConfig): void {
    this.OKTA_CONFIG = config
  }
}
