import { type Router } from '@angular/router'
import { type OktaAuth } from '@okta/okta-auth-js'
import { IS_A_STRING_AND_NOT_EMPTY, IS_SET } from 'src/app/util/check.util'
import { type OktaAuthConfigService } from './okta-auth-config.service'

const OKTA_AUTH_FACTORY_SETUP = async (_: {
  oktaAuth: OktaAuth
  router?: Router
}): Promise<void> => {
  // * Basic implementation of `restoreOriginalUri`
  if (!IS_SET(_.oktaAuth.options.restoreOriginalUri) && IS_SET(_.router)) {
    const router = _.router

    // eslint-disable-next-line max-params
    _.oktaAuth.options.restoreOriginalUri = async (_oktaAuthOAuth, originalUri) => {
      await router.navigateByUrl(IS_A_STRING_AND_NOT_EMPTY(originalUri) ? originalUri : '/')
    }
  }

  // * start okta auth services
  await _.oktaAuth.start()
}

// eslint-disable-next-line max-params
export const OKTA_AUTH_FACTORY = async (oktaAuthConfigService: OktaAuthConfigService, router?: Router): Promise<OktaAuth> => {
  const config = oktaAuthConfigService.getConfig()

  if (!IS_SET(config)) {
    throw new Error('Okta config is not provided')
  }

  const oktaAuth = config.oktaAuth

  if (!IS_SET(oktaAuth)) {
    throw new Error('Okta config should contain oktaAuth')
  }

  console.log('OKTA_AUTH_FACTORY', { config })

  let err: any

  try { await OKTA_AUTH_FACTORY_SETUP({ oktaAuth, router }) } catch (e) { err = e }

  if (IS_SET(err)) {
    console.error('Error in OKTA_AUTH_FACTORY_SETUP:', err)
    throw new Error('OKTA_AUTH_FACTORY_SETUP')
  }

  return oktaAuth
}
