import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, inject, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule, type MatLuxonDateAdapterOptions } from '@angular/material-luxon-adapter'
import { MatIconRegistry } from '@angular/material/icon'
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import OktaAuth from '@okta/okta-auth-js'
import { lastValueFrom } from 'rxjs'
import { MaterialModule } from 'src/app/app-material.module'
import { SnackbarComponent } from 'src/app/core/snackbar/snackbar.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ConfirmDialogComponent } from './core/dialog/confirm-dialog.component'
import { TextPreviewDialogComponent } from './core/dialog/text-preview-dialog.component'
import { OKTA_AUTH_OPTIONS, OKTA_CONFIG, type OktaAuthOptionsExtended } from './core/okta/okta.config'
import { OktaAuthModule } from './core/okta/okta.module'
import { type SynapseInfo } from './util/auth.util'
import { IS_ON } from './util/check.util'
import { TO_STRING } from './util/convert.util'
import { GET_ENV_HOSTNAME, GET_ENV_SYNAPSE_CLIENT_ID, GET_ENV_SYNAPSE_INFO_URI, IS_ENV_DEV, IS_ENV_RSS } from './util/env.util'

// /**
//  * 0 = Sunday, 1 = Monday, etc
//  */
// const firstDayOfWeek = (locale: string): number => {
//   return getWeekStartByLocale(locale)
// }

const matLuxonDateAdapterOptions: MatLuxonDateAdapterOptions = { firstDayOfWeek: 1, useUtc: false, defaultOutputCalendar: 'gregory' }

// eslint-disable-next-line max-params
function synapseConfigInitializer (handler: HttpBackend, config: OktaAuthOptionsExtended) {
  return async () => {
    if (await IS_ENV_RSS()) {
      try {
        const synapseInfoUri = await GET_ENV_SYNAPSE_INFO_URI()
        const synapseClientId = await GET_ENV_SYNAPSE_CLIENT_ID()
        const synapseInfo = await lastValueFrom(new HttpClient(handler).get<SynapseInfo>(synapseInfoUri))
        const clientId = TO_STRING(synapseClientId)
        const issuer = synapseInfo.issuer
        const appHostname = await GET_ENV_HOSTNAME()
        const scopes = ['openid', 'email', 'offline_access']
        const isEnvDev = await IS_ENV_DEV()
        const baseUri = appHostname === '__WEB_CLIENT_HOSTNAME__' ? window.location.origin : appHostname
        const tokenUrl = synapseInfo.token_endpoint
        const authorizeUrl = synapseInfo.authorization_endpoint
        const logoutUrl = synapseInfo.end_session_endpoint
        const revokeUrl = synapseInfo.revocation_endpoint

        Object.assign(config, {
          clientId,
          issuer,
          redirectUri: baseUri,
          postLogoutRedirectUri: `${baseUri}/logged-out`,
          scopes,
          pkce: true,
          responseMode: 'query',
          responseType: 'code',
          testing: { disableHttpsCheck: isEnvDev },
          tokenUrl,
          authorizeUrl,
          logoutUrl,
          revokeUrl,
        })
      } catch {
        console.error('Synapse configuration error')
      }
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    MatLuxonDateModule,
    OktaAuthModule,
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: matLuxonDateAdapterOptions },
    { provide: OKTA_AUTH_OPTIONS, useValue: {} },
    { provide: APP_INITIALIZER, useFactory: synapseConfigInitializer, deps: [HttpBackend, OKTA_AUTH_OPTIONS], multi: true },
    {
      provide: OKTA_CONFIG,
      useFactory: (config: OktaAuthOptionsExtended) => {
      // ? Initialize with options empty object to avoid error on "oktaAuth.message.xxxx"
        let oktaAuth: OktaAuth = { options: {} } as OktaAuth

        try {
          oktaAuth = new OktaAuth(config)
        } catch {
          console.error('OktaAuth initialization error')
        }

        return {
          oktaAuth,
          onAuthRequired: async (oktaAuth: OktaAuth) => {
            const triggerLogin = async (): Promise<void> => {
              await oktaAuth.signInWithRedirect().catch(console.error)
            }

            if (!IS_ON(oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated)) {
            // * app initialization stage
              await triggerLogin()
            } else {
            // * ask the user to trigger the login process during token autoRenew process
              const bConfirmed = confirm('Do you want to re-authenticate?')

              if (bConfirmed) {
                await triggerLogin()
              }
            }
          },
          onAuthResume: async (oktaAuth: OktaAuth & { options: { restoreOriginalUri: (okAuth: OktaAuth) => {} } }) => {
            try {
              const originalUri = oktaAuth.getOriginalUri()
              await oktaAuth.handleRedirect(originalUri)
            } catch (error) {
              console.error('Okta handleRedirect error:', error)
            }
          },
        }
      },
      deps: [OKTA_AUTH_OPTIONS],
    },
  ],
  declarations: [
    AppComponent,

    SnackbarComponent,

    ConfirmDialogComponent,
    TextPreviewDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  IconRegistry = inject(MatIconRegistry)
  DomSanitizer = inject(DomSanitizer)

  svgIconSetPath = 'assets/icons.svg'

  constructor () {
    this.IconRegistry.addSvgIconSet(this.DomSanitizer.bypassSecurityTrustResourceUrl(this.svgIconSetPath))
  }
}
