import { type ModuleWithProviders, NgModule, Optional } from '@angular/core'
import { Router } from '@angular/router'
import { OktaAuthConfigService } from './okta-auth-config.service'
import { OKTA_AUTH_FACTORY } from './okta-auth-factory-setup'
import { OKTA_AUTH, OKTA_CONFIG, type OktaConfig } from './okta.config'

@NgModule({
  providers: [
    {
      provide: OKTA_AUTH,
      useFactory: OKTA_AUTH_FACTORY,
      deps: [
        OktaAuthConfigService,
        [new Optional(), Router],
      ],
    },
  ],
})
export class OktaAuthModule {
  static forRoot (config?: OktaConfig): ModuleWithProviders<OktaAuthModule> {
    return {
      ngModule: OktaAuthModule,
      providers: [
        { provide: OKTA_CONFIG, useValue: config },
      ],
    }
  }

  // ! should not have constructor to support lazy load of config with APP_INITIALIZER
}
