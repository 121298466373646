import { Component, inject, type OnInit } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { combineLatest, switchMap } from 'rxjs'
import { SnackbarService } from 'src/app/core/snackbar/snackbar.service'
import { AuthService } from './core/auth/auth.service'
import { DictionaryService } from './core/context/dictionary.service'
import { LocaleService } from './core/context/locale.service'
import { IS_SET } from './util/check.util'
import { SET_LOCALE } from './util/space.util'
import { SET_TZ } from './util/time.util'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  MatSnackBar = inject(MatSnackBar)
  MatSnackBarService = inject(SnackbarService)
  DateAdapter = inject(DateAdapter)
  LocaleService = inject(LocaleService)
  DictionaryService = inject(DictionaryService)
  AuthService = inject(AuthService)

  bDarkTheme = false

  title = 'ssclient'

  currentUserData?: any

  async ngOnInit (): Promise<void> {
    this.MatSnackBarService.matSnackBar = this.MatSnackBar

    this.syncDarkModeBodyClass()

    if ('fonts' in document) {
      await document.fonts.ready
      document.body.classList.add('fonts_loaded')
    }

    combineLatest([this.AuthService.currentUserDataBS, this.LocaleService.localeBS, this.LocaleService.tzBS]).pipe(
      switchMap(async ([currentUserData, locale, tz]) => {
        SET_LOCALE(locale)
        SET_TZ(tz)

        this.DateAdapter.setLocale(locale)

        const currentUserHrJson = IS_SET(currentUserData) ? JSON.stringify(currentUserData, null, 2) : undefined

        const lastUserHrJson = this.DictionaryService.lastUserHrJsonBS.value
        const mLastRefreshLocale = this.DictionaryService.mLastRefreshLocaleBS.value
        const mLastRefreshTz = this.DictionaryService.mLastRefreshTzBS.value

        if (
          IS_SET(currentUserData) &&
          (currentUserHrJson !== lastUserHrJson) &&
          ((mLastRefreshLocale !== locale) || (mLastRefreshTz !== tz))
        ) {
          await this.DictionaryService.refresh()
        }
      })
    ).subscribe()
  }

  syncDarkModeBodyClass (): void {
    if (this.bDarkTheme) {
      document.body.classList.add('dark_theme')
    } else {
      document.body.classList.remove('dark_theme')
    }
  }

  onDarkModeToggleRequested (): void {
    this.bDarkTheme = !this.bDarkTheme
    this.syncDarkModeBodyClass()
  }
}
